@font-face {
  font-family: "FuturaPT-Demi";
  src: url("/fonts/ParaType - Futura PT Book.ttf") format("truetype");
}

@font-face {
  font-family: "FuturaPT-Medium";
  src: url("/fonts/ParaType - Futura PT Medium.ttf") format("truetype");
}

@font-face {
  font-family: "FuturaPT-Heavy";
  src: url("/fonts/ParaType - Futura PT Bold.ttf") format("truetype");
}

html,
body {
  font-family: FuturaPT-Demi;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.pure-table {
  /* Remove spacing between table cells (from Normalize.css) */
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #cbcbcb;
}

.pure-table td,
.pure-table th {
  border-left: 1px solid #cbcbcb; /*  inner column border */
  border-width: 0 0 0 1px;
  font-size: inherit;
  margin: 0;
  overflow: visible; /*to make ths where the title is really long work*/
  padding: 0.5em 1em; /* cell padding */
}

.pure-table thead {
  background-color: #e8e8e8;
  color: #000;
  text-align: left;
  vertical-align: bottom;
}

.pure-table td {
  background-color: transparent;
}

/* BORDERED TABLES */
.pure-table-bordered td {
  border-bottom: 1px solid #cbcbcb;
}
.pure-table-bordered tbody > tr:last-child > td {
  border-bottom-width: 0;
}
